import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
      <div className="row justify-content-center">
  <div className="col-xs-12 col-md-12 ">
    <img src="img/aboutus.jpg" className="img-responsive" style={{width:"auto"}} alt="" />
  </div>
</div>
<div className="row">
  
  <div className="col-xs-12 col-md-12 " >
    <div className="about-text">
      <h2>History</h2>
      <p>
        Despite common belief, the concept of home automation isn’t that new.
        For over a century, science fiction has imagined our future homes as
        completely automated and with robots helping us with household chores.
        The prolific science fiction writer Ray Bradbury went as far as to
        imagine an automated home that continues to work despite no one living
        in it.
      </p>
    </div>
  </div>
</div>

        <div className="row">
         
          <div className="col-xs-12 col-md-12">
            <div className="about-text ">
              <h2>Vision</h2>
              <p>
        The vision of home automation goes beyond simply controlling lights and appliances. It’s about creating smart,
        intuitive living spaces that adapt to the needs and preferences of the residents. Future homes will anticipate
        your daily routines, optimize energy consumption, and seamlessly integrate with emerging technologies like AI,
        IoT, and sustainable energy sources. The goal is to create a comfortable, efficient, and eco-friendly environment
        that enhances the quality of life.
      </p>
            </div>
          </div>
        </div>

        <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Values</h2>
             <h3>Convenience</h3>
             <p>
             Automating routine tasks such as turning lights on/off, adjusting the thermostat, or controlling home appliances remotely via smartphone or voice commands provides significant convenience.
           </p>
           <h3>Energy Efficiency</h3>
           <p>Smart thermostats, lighting, and energy-monitoring systems help optimize energy usage, reducing waste and lowering utility bills by automating and regulating power consumption.</p>
           <h3>Security</h3>
           <p>Home automation systems often integrate security cameras, smart locks, motion sensors, and alarms that can be monitored and controlled remotely, enhancing home security and peace of mind.</p>
           <h3>Comfort</h3>
           <p>Automation allows for precise control over heating, cooling, lighting, and even entertainment systems. You can customize settings for different rooms or situations, improving overall comfort.</p>
           <h3>Accessibility</h3>
           <p>For elderly or disabled individuals, home automation provides accessibility by allowing control of home systems without the need for physical movement—enhancing independence and quality of life.</p>
           <h3>Cost Savings</h3>
           <p>Over time, home automation can help save money by reducing energy costs, preventing water leaks, or optimizing appliance use, leading to fewer repairs and reduced utility expenses.</p>
           <h3>Safety</h3>
           <p>Automated safety features like gas or smoke detectors, water leak sensors, and alerts can notify homeowners of potential hazards, allowing for quick action.</p>
           <h3>Customization</h3>
           <p>Smart homes allow users to create personalized settings and routines, such as scheduling the lights, adjusting the thermostat, or even automating specific devices based on personal preferences.</p>
           <h3>Remote Control and Monitoring</h3>
           <p>The ability to remotely monitor and control various home devices through a smartphone app provides real-time insight and control over the home environment, no matter where you are.</p>
           <h3>Integration</h3>
           <p>Modern home automation systems can integrate with a variety of devices, offering a unified platform to control everything from entertainment to security to energy management.</p>
           </div>
         </div>
       </div>

       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Social Proof</h2>
             <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" style={{width:60,height:60}} />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="row">
        
        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/acheivement1.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/acheivement2.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/acheivement3.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/acheivement4.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/acheivement5.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/acheivement6.jpg" alt="" style={{width:100,height:100}} />
        </div>
     
</div>

           </div>
         </div>
       </div>
       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Target Market</h2>
             <h3>Homeowners</h3>
             <p><b>Young Professionals and Tech-Savvy Consumers:</b> Millennials and Gen Z, who are early adopters of new technology, are often looking for convenience, control, and integration with their digital lifestyles.</p>
             <p><b>Families:</b> Families with children benefit from enhanced security features, energy efficiency, and convenience. Home automation helps busy households manage daily tasks like controlling lighting, temperature, and appliances remotely.</p>
           
           <h3>Smart Apartments and Rental Properties</h3>
           <p><b>Property Owners and Managers:</b> Landlords and property management companies invest in home automation to increase the value of their rental properties, attract tech-forward tenants, and reduce energy costs.</p>
           <p><b>Tenants:</b> Tenants in smart apartments benefit from features like keyless entry, automated lighting, and energy-saving tools, making smart homes attractive in competitive rental markets.</p>
          
          <h3>Elderly and Aging Population</h3>
          <p><b>Seniors and Assisted Living Facilities:</b> Home automation provides accessibility and independence for elderly individuals through smart health monitoring devices, fall detection, voice-controlled systems, and automated alerts.</p>
          <p><b>Caregivers:</b> Family members and professional caregivers can remotely monitor and control key functions in the homes of elderly individuals, ensuring their safety and comfort.</p>
           
           <h3>Environmentally Conscious Consumers</h3>
           <p><b>Eco-Friendly Households:</b> People committed to sustainability and reducing their carbon footprint are drawn to smart home technologies that manage energy consumption, such as smart thermostats, solar panel integrations, and automated lighting and water systems.</p>
          
          <h3>Security-Focused Consumers</h3>
          <p><b>Safety-Conscious Individuals:</b> Consumers prioritizing safety and security are interested in smart locks, cameras, doorbells, and automated security systems. Remote monitoring and control of home security appeal to frequent travelers and homeowners concerned with neighborhood safety.</p>
          
          <h3> Developers and Builders</h3>
          <p><b>Real Estate Developers:</b> Many developers are integrating smart home technologies into new housing projects to meet growing demand. Smart homes add market value and attract a wide range of buyers who seek convenience and energy efficiency.</p>
          <p><b>Construction and Renovation:</b> Builders and renovation contractors are adopting smart home tech as part of high-end remodels, offering added value and competitive features.</p>
          
          <h3>Tech Enthusiasts</h3>
          <p><b>Early Adopters and Innovators:</b> These are individuals who embrace the latest technologies and enjoy experimenting with smart home systems, AI integration, and IoT-connected devices. They value innovation and are constantly upgrading their home automation systems.</p>
          
          <h3>Utility Companies and Energy Providers</h3>
          <p><b>Energy Providers:</b> Utility companies offer smart home technology solutions to help customers manage their energy usage, leading to cost savings and reduced environmental impact.</p>
          
          <h3>Real Estate Investors</h3>
          <p><b>Investors:</b> Smart home automation increases property value, making it appealing for real estate investors looking to maximize returns through rentals or property sales.</p>
          
          <h3>Smart Cities and Government Programs</h3>
          <p><b>Governments and Municipalities:</b> Local governments are promoting smart city initiatives, including home automation, to create more sustainable, energy-efficient urban environments. Public housing projects are also adopting smart home technologies to improve living conditions.</p>
          
           </div>
         </div>
       </div>

       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Process</h2>
             <h3>Initial Consultation</h3>
             <p><b>Needs Assessment:</b> Meet with the client to understand their specific needs, preferences, and goals for home automation.</p>
           <p><b>Site Evaluation:</b> Conduct an evaluation of the home to identify existing systems, infrastructure, and any potential challenges</p>
           
           <h3>Design and Planning</h3>
           <p><b>Customized Solution:</b> Develop a tailored plan that outlines the specific home automation solutions, including devices and systems to be installed (e.g., lighting, security, HVAC, entertainment).</p>
           <p><b>Budgeting:</b> Provide a detailed estimate of costs associated with the proposed solutions, including hardware, software, and installation services.</p>
          
          <h3>Product Selection</h3>
          <p><b>Device Selection:</b> Recommend specific brands and models of smart devices based on compatibility, features, and client preferences.</p>
          <p><b>Sourcing:</b> Acquire necessary equipment and devices from reliable suppliers or manufacturers.</p>

          <h3>Installation</h3>
          <p><b>Scheduling:</b> Coordinate with the client to schedule the installation at a convenient time.</p>
          <p><b>Installation Procedures:</b> Install the hardware and software components of the home automation system, which may include:
          <p><b>1 </b>Mounting and wiring devices (e.g., cameras, sensors, smart thermostats).</p>
          <p><b>2 </b>Setting up network infrastructure (e.g., Wi-Fi routers, hubs).</p>
          <p><b>3 </b>Installing mobile apps or software for system control.</p>
          </p>

          <h3>Configuration and Integration</h3>
          <p><b>System Configuration:</b> Configure devices and systems for optimal performance, ensuring they communicate effectively with one another.</p>
          <p><b>Integration:</b> Integrate various components of the home automation system, such as connecting lighting, security systems, and HVAC controls into a unified platform.</p>
          
          <h3>Testing and Quality Assurance</h3>
          <p><b>System Testing:</b> Test all components of the home automation system to ensure they are functioning correctly and meet the client’s requirements.</p>
          <p><b>Troubleshooting:</b> Identify and resolve any issues that arise during testing.</p>

          <h3>Training and Support</h3>
          <p><b>User Training:</b> Provide training for the client on how to use the home automation system, including mobile apps, voice controls, and other interfaces.</p>
          <p><b>Documentation:</b> Supply manuals and guides for devices and systems, detailing operational procedures and troubleshooting tips.</p>
          
          <h3>Ongoing Maintenance and Support</h3>
          <p><b>Technical Support:</b> Offer ongoing support services for troubleshooting, repairs, and updates to the home automation system.</p>
          <p><b>Regular Maintenance:</b> Provide maintenance services to ensure that devices and systems continue to function properly and remain updated with the latest software.</p>
          
          <h3>Feedback and Improvement</h3>
          <p><b>Client Feedback:</b> Gather feedback from clients regarding their experience with the home automation system and services provided.</p>
           <p><b>System Upgrades:</b> Recommend and implement upgrades or expansions to the system as new technologies emerge or as the client’s needs evolve.</p>
          
          <h3>Marketing and Outreach</h3>
          <p><b>Promotions:</b> Use customer testimonials and case studies to promote services to potential clients.</p>
          <p><b>Community Engagement:</b> Participate in local events, workshops, or online forums to educate potential customers about the benefits of home automation.</p>

           </div>
         </div>
       </div>
       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Associated Brands</h2>
             <div className="row">
        
        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/brand1.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/brand2.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/brand3.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/brand4.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/brand5.jpg" alt="" style={{width:100,height:100}} />
        </div>

        <div  className="col-xs-6 col-md-2">
          {" "}
          <img src="img/homeInnovation/brand6.jpg" alt="" style={{width:100,height:100}} />
        </div>
     
</div>
           </div>
         </div>
       </div>
      </div>
    </div>
  );
};
