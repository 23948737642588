import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
            dapibus leonec.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
          <img src="img/homeInnovation/innovation.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-md-6">
          <div className="service-desc">
                    <h3>New Smart Home Standard</h3>
                    <p> The technology leader celebrated for its groundbreaking products and services, is making a significant investment in Matter, the emerging smart home standard governed by the Connectivity Standards Alliance (CSA). In spite of hurdles in adoption and consumer confusion, Apple firmly stands by its commitment to Matter, with the goal of simplifying and harmonizing the smart home experience for consumers worldwide.</p>
                  </div>
          </div>
        </div>
        <div className="row">
        <div className="col-md-6">
          <div className="service-desc">
                    <h3>New Smart Security</h3>
                    <p> Having your own home is one of the biggest achievements and investments in your life. So, it’s your priority to keep it safe and secure. But how? Thanks to Smart security solutions, it’s easier to elevate your home’s intelligence and make it safer.</p>
                  </div>
          </div>
          <div className="col-md-6">
          <img src="img/homeInnovation/security.jpg" className="img-responsive"  alt="" />
          </div>
         
        </div>
        <div className="row">
          <div className="col-md-6">
          <img src="img/homeInnovation/elderlyCare.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-md-6">
          <div className="service-desc">
                    <h3>New Smart Elderly Care</h3>
                    <p> devices for elderly can detect the living trajectory of the elderly and protect the elderly safety by using inconspicuous wireless sensors located in the home to automatically learn the daily behavior patterns of senior citizens and then detect behavioral changes that may cause concern. It is able to reminds family members or carers through messages</p>
                  </div>
          </div>
        </div>
      </div>
    </div>
  );
};
