import React, { useState, useEffect } from "react";
import {Navigation} from "../components/landingPage/navigation";
import { About } from "../components/landingPage/about";
import {Footer } from "../components/landingPage/footer";
import JsonData from "../data/data.json";

const AboutUsPage=()=>{

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);


    return (
        <>
         <Navigation />
         <About data={landingPageData.Testimonials} />
         <Footer />
        </>
       
    )
}

export default AboutUsPage;