import React from "react";
import { GoogleOAuthProvider } from '@react-oauth/google';
import {Navigation} from "../components/landingPage/navigation";
import {Footer } from "../components/landingPage/footer";
import LoginForm from "../components/login/loginForm";

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const LoginPage=()=>{

    return (
        <>
         <Navigation />
         <GoogleOAuthProvider clientId={clientId}>
            <LoginForm />
        </GoogleOAuthProvider>
         <Footer />
        </>
       
    )
}

export default LoginPage;