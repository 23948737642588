import React, { useState, useEffect } from "react";
import {Navigation} from "../components/landingPage/navigation";
import { Contact } from "../components/landingPage/contact";
import {Footer } from "../components/landingPage/footer";
import JsonData from "../data/data.json";

const ContactUsPage=()=>{

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

    return (
        <>
         <Navigation />
         <Contact data={landingPageData.Contact} />
         <Footer />
        </>
       
    )
}

export default ContactUsPage;