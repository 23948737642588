import React from "react";
import {Navigation} from "../components/landingPage/navigation";
import {Footer } from "../components/landingPage/footer";
import FAQList from "../components/faq/faqList";


const FAQPage=()=>{

  

    return (
        <>
         <Navigation />
         <FAQList  />
         <Footer />
        </>
       
    )
}

export default FAQPage;