import React from "react";
 import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {

  const navigate = useNavigate();

  const handleClick=()=>{
    navigate('/');
  };
  return (
    <div id="">
     
   <div className="justify-content center">
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-12 text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center">404</h1>
              </div>
              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>
                <p>The page you are looking for is not available!</p>
                <button onClick={handleClick} className="link_404">Go To Home</button>
              </div>
            </div>
          </div>
        </div>
        </div>
    </section>
  </div>
  </div>
  );
};

export default PageNotFound;