import React from "react";
import { GoogleLogin } from '@react-oauth/google';

const LoginForm = () => {
    const onSuccess = (credentialResponse) => {
        const token = credentialResponse.credential; // Get the token
        localStorage.setItem('authToken', token);
        console.log('Login Success:', credentialResponse);
        console.log('Token:', token);
    };

    const onFailure = (error) => {
        console.log('Login Failed:', error);
    };

    return (
        <header id="header">
            <div className="intro">
                <div className="overlay">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-md-offset-2 intro-text">
                                <h1>{"Login Form"}</h1>
                                <div className="btn btn-custom btn-lg page-scroll">
                                    <GoogleLogin onSuccess={onSuccess} onFailure={onFailure}  theme="outline" 
                size="large" 
                shape="rectangular"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default LoginForm;