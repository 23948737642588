import React,{useState} from "react";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export const Navigation = (props) => {

  const location = useLocation();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSubmenuOpen1, setIsSubmenuOpen1] = useState(false);
  const [isSubmenuOpen2, setIsSubmenuOpen2] = useState(false);
  const [isSubmenuOpen3, setIsSubmenuOpen3] = useState(false);
  

  const handleDropdownOnMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleSubmenu1=()=>{
    setIsSubmenuOpen1(true);
  };
  const handleSubmenu2=()=>{
    setIsSubmenuOpen2(true);
  };
  const handleSubmenu3=()=>{
    setIsSubmenuOpen3(true);
  };
  
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          {location.pathname==="/"?<a className="navbar-brand page-scroll" href="#page-top">
            React Landing Page
          </a>
          :
          <Link to="/" className="navbar-brand page-scroll">
               React Landing Page
             </Link>
        }
          
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
          <Link to="/" className="page-scroll">
               Home
             </Link>
            </li>
            <li>
              <a href="#ser" className="page-scroll" onMouseEnter={handleDropdownOnMouseEnter}>
                Services
              </a>
              {isDropdownOpen && (
            <ul className="dropdown-menu">
              <li>
                <a href="#service1" onMouseEnter={handleSubmenu1}>Home Innovation</a>
                {isSubmenuOpen1 && (
                  <ul className="submenu">
                    <li>
                      <a href="#sub-service1">Lighting Control</a>
                    </li>
                    <li>
                      <a href="#sub-service2">Climate Control</a>
                    </li>
                    <li>
                      <a href="#sub-service3">Automatic Curtain</a>
                    </li>
                    <li>
                      <a href="#sub-service1">Smart Pet Feeders</a>
                    </li>
                    <li>
                      <a href="#sub-service2">Smart Irrigation</a>
                    </li>
                    <li>
                      <a href="#sub-service3">Smart Water System</a>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <a href="#service2" onMouseEnter={handleSubmenu2}>Home Security</a>
                {isSubmenuOpen2 && (
                  <ul className="submenu">
                    <li>
                      <a href="#sub-service1">CCTV System</a>
                    </li>
                    <li>
                      <a href="#sub-service2">Alarming System</a>
                    </li>
                    <li>
                      <a href="#sub-service3">Doors with Smart Locking system</a>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <a href="#service3" onMouseEnter={handleSubmenu3}>Elderly Support</a>
                {isSubmenuOpen3 && (
                  <ul className="submenu">
                    <li>
                      <a href="#sub-service1">Health Monitoring System</a>
                    </li>
                    <li>
                      <a href="#sub-service2">Emergency Support</a>
                    </li>
                    <li>
                      <a href="#sub-service3">House help Support</a>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          )}
            </li>
            <li>
              <Link to="/about" className="page-scroll">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contact" className="page-scroll">
                Contact Us
              </Link>
            </li>
            <li>
            <a href="#search" className="page-scroll">
              <i className="fa fa-search"></i>
              </a>
            </li>
            <li>
              <Link to="/login" className="page-scroll">
              <i className="fa fa-user"></i>
              </Link>
            </li>
           
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li>
            <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            
          </ul>
        </div>
      </div>
    </nav>
  );
};
