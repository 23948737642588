import React from "react";

export const Policy = (props) => {
  return (
    <div id="about">
      <div className="container">
      {/* <div className="row justify-content-center">
  <div className="col-xs-12 col-md-12 ">
    <img src="img/aboutus.jpg" className="img-responsive" style={{width:"auto"}} alt="" />
  </div>
</div> */}
<div className="row">
  
  <div className="col-xs-12 col-md-12 " >
    <div className="about-text">
      <h2>Introduction</h2>
      <p>
      At [Your Business Name], your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website and use our home automation services.
      </p>
    </div>
  </div>
</div>

        <div className="row">
         
          <div className="col-xs-12 col-md-12">
            <div className="about-text ">
              <h2>Information We Collect</h2>
              <h3>Personal Information</h3>
              <p> We may collect personal information that you provide to us, including but not limited to:</p>
              <p><b>Email address</b></p>
              <p><b>Phone number</b></p>

              <h3>Device and Usage Information</h3>
              <p> We automatically collect information about your device and usage, including:</p>
              <p><b>IP address</b></p>
              <p><b>Browser type and version</b></p>
              <p><b>Pages visited on our site</b></p>
              <p><b>Time and date of visits</b></p>
              <p><b>Time spent on each page</b></p>
       
            </div>
          </div>
        </div>

        <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>How We Use Your Information</h2>
             <p>We use your information for various purposes, including:</p>
             <p><b>To provide and maintain our services</b></p>
             <p><b>To process transactions and send confirmations</b></p>
             <p><b>To improve and personalize your experience</b></p>
             <p><b>To communicate with you about your account or services</b></p>
             <p><b>To send promotional materials (with your consent)</b></p>
           </div>
         </div>
       </div>

       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Information Sharing</h2>
             <p>We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:</p>
             <p><b>Service Providers:</b>We may share your information with trusted third-party vendors who assist us in operating our website and providing our services, subject to strict confidentiality obligations.</p>
             <p><b>Legal Requirements:</b>We may disclose your information if required by law or to protect our rights, privacy, safety, or property, or that of others.</p>
           </div>
         </div>
       </div>
       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Data Security</h2>
             <p>We implement appropriate security measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure.</p>
           </div>
         </div>
       </div>

       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Your Rights</h2>
             <p>You have the right to:</p>
             <p><b>Access, correct, or delete your personal information.</b></p>
             <p><b>Opt-out of marketing communications.</b></p>
             <p><b>Request a copy of your data.</b></p>
             <p>To exercise these rights, please contact us at [Insert Contact Information].</p>
           </div>
         </div>
       </div>
       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Cookies and Tracking Technologies</h2>
            <p>Our website uses cookies and similar tracking technologies to enhance your experience. You can choose to accept or decline cookies through your browser settings. However, this may affect your ability to use certain features of our site.</p>
           </div>
         </div>
       </div>

       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Changes to This Privacy Policy</h2>
             <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on our website with a revised effective date.</p>
           </div>
         </div>
       </div>

       <div className="row">
         
         <div className="col-xs-12 col-md-12">
           <div className="about-text ">
             <h2>Contact Us</h2>
             <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
             <p><b>[Your Business Name]</b></p>
             <p>
                <span>
                  <i className="fa fa-map-marker"></i> <b>Address :</b>
                </span>{" "}
                {"4321 California St, San Francisco, CA 12345"}
              </p>
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> <b>Email :</b> 
                </span>{" "}
                {"info@company.com"}
              </p>
              <p>
                <span>
                  <i className="fa fa-phone"></i> <b>Phone :</b>
                </span>{" "}
                {"+1 123 456 1234"}
              </p>
              <p>Thank you for choosing [Your Business Name] for your home automation needs!</p>
           </div>
         </div>
       </div>
      </div>
    </div>
  );
};
