import React,{useState} from "react";
import emailjs from "emailjs-com";
import {Link } from "react-router-dom";
const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Footer = (props) => {

  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    

    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (

    <div className="my-5">

      <footer
        className="text-center text-lg-start text-dark"
        style={{ backgroundColor: "#ECEFF1" }}
      >

        <section
          className="d-flex justify-content-between p-4 text-white"
          style={{ backgroundColor: "#21D192" }}
        >

          {/* <div className="me-5">
            <span>Get connected with us on social networks:</span>
          </div> */}

        </section>



        <section className="">
          <div className="container text-center text-md-start mt-5">

            <div className="row mt-3">

            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

<h6 className="text-uppercase fw-bold">Help</h6>
<hr
  className="mb-4 mt-0 d-inline-block mx-auto"
  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
/>
<p>
  <a href="#!" className="text-dark">Call Us</a>
</p>
<p>
  <a href="#!" className="text-dark">Email Us</a>
</p>
<p>
  <a href="#!" className="text-dark">Contact Us</a>
</p>
<p>
<div className="social">
            <a href="#!" className="text-white me-4" style={{padding:'10px'}}>
              <i className="fa fa-facebook"></i>
            </a>
            <a href="#!" className="text-white me-4" style={{padding:'10px'}}>
              <i className="fa fa-twitter"></i>
            </a>
            <a href="#!" className="text-white me-4" style={{padding:'10px'}}>
              <i className="fa fa-google"></i>
            </a>
            <a href="#!" className="text-white me-4" style={{padding:'10px'}}>
              <i className="fa fa-instagram"></i>
            </a>
            <a href="#!" className="text-white me-4" style={{padding:'10px'}}>
              <i className="fa fa-linkedin"></i>
            </a>
            {/* <a href="#!" className="text-white me-4" style={{padding:'10px'}}>
              <i className="fa fa-github"></i>
            </a> */}
          </div>
</p>

</div>

<div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                <h6 className="text-uppercase fw-bold">Company</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                  <a href="#!" className="text-dark">About Us</a>
                </p>
                <p>
                  <a href="#!" className="text-dark">Contact Us</a>
                </p>
                {/* <p>
                  <a href="#!" className="text-dark">Vision</a>
                </p>
                <p>
                  <a href="#!" className="text-dark">Team</a>
                </p> */}
              </div>

              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                <h6 className="text-uppercase fw-bold">Services</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                  <a href="#!" className="text-dark">Home Security</a>
                </p>
                <p>
                  <a href="#!" className="text-dark">Home Innovation</a>
                </p>
                <p>
                  <a href="#!" className="text-dark">Elderly Support</a>
                </p>
               
              </div>



              <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

                <h6 className="text-uppercase fw-bold">Useful Links</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                <p>
                  <Link to="/policy" className="text-dark">Privacy Policy</Link>
                </p>
                <p>
                  <Link to="/FAQ" className="text-dark">FAQ</Link>
                </p>
                {/* <p>
                  <a href="#!" className="text-dark">Portfolio</a>
                </p> */}
                
              </div>



              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

                <h6 className="text-uppercase fw-bold">News Letter</h6>
                <hr
                  className="mb-4 mt-0 d-inline-block mx-auto"
                  style={{ width: "60px", backgroundColor: "#7c4dff", height: "2px" }}
                />
                {/* <p><i className="fa fa-home mr-3"></i> New York, NY 10012, US</p>
                <p><i className="fa fa-envelope mr-3"></i> info@example.com</p>
                <p><i className="fa fa-phone mr-3"></i> + 01 234 567 88</p>
                <p><i className="fa fa-print mr-3"></i> + 01 234 567 89</p> */}

<form name="sentMessage"  onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Enter Your Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  

                </div>
              
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Subscribe
                </button>
              </form>
              </div>

            </div>

          </div>
        </section>

        <div
          className=" text-center me-5 p-4"  
          style={{ backgroundColor: "#21D192",height:"40px" }}
        >
          © 2020 Copyright:
          <a className="text-dark" href="https://mdbootstrap.com/"
          >MDBootstrap.com</a>
        </div>

      </footer>

    </div>


  );
};
