
import React from "react";
import LandingPage from "./pages/landingPage";
import LoginPage from "./pages/login";
import ContactPage from "./pages/contactus";
import AboutPage from "./pages/aboutus";
import FAQPage from "./pages/faq";
import PrivacyPolicyPage from "./pages/privacyPolicy";
import PageNotFound from "./pages/pageNotFound";
import SmoothScroll from "smooth-scroll";
import { BrowserRouter,Routes, Route } from 'react-router-dom';
//  import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";



export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
 
  return (
    <div>
       <BrowserRouter>
       <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/FAQ" element={<FAQPage />} />
      <Route path="/policy" element={<PrivacyPolicyPage />} />
      <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
      
    </div>
  );
};

export default App;

